import React, { useEffect } from 'react'
import image from './solar.png'
import './renewableEnergy.css'
import Aos from 'aos'
import 'aos/dist/aos.css'

const RenewableEnergy = () => {
  useEffect(()=> {
    Aos.init({
        duration: 1000
    })
  },[])

  return (
    <div className='renewable-energy'>
        <div data-aos="slide-left" className="renewable-energy-content">
            <div className="renewable-energy-header">
                <p>//POWER</p>
                <h1>Renewable Energy</h1>
            </div>
            <div className="renewable-energy-desc">
                <p>We offer various renewable power backup solutions for your business. We study your business and recommend the appropriate power requirement to suit your budget. Renewable energy source like solar and inverter is common in the Nigerian market. It's clean with no pollution whatsoever. You can get as much as 24/7 uninterruptible power supply at home and office.</p>
            </div>
        </div>
        <img data-aos="slide-right" src={image} className="renewable-energy-image"/>
    </div>
  )
}

export default RenewableEnergy