import React from 'react'
import Banner from '../../components/Banner/Banner'
import Hero from '../../components/Hero/Hero'
import Navbar from '../../components/Navbar/Navbar'
import ServicesBlock from '../../components/ServicesComponents/ServicesBlocks/ServicesBlock'
import ServicesBanner from '../../components/ServicesComponents/ServicesBanner/ServicesBanner'
import image from './services.png'
import Footer from '../../components/footer/Footer'
const Services = () => {
  return (
    <div>
        <Banner/>
        <Navbar active='servicesPage'/>
        <Hero
            title='Our Services Stand out Among Our Competitors.'
            desc='Newistt offers various innovative solutions to suit your exact need. The people behind our technology make all the difference.'
            button='Contact Us'
            link='contact-us'
            image={image}
        />
        <ServicesBlock/>
        <ServicesBanner/>
        <Footer/>
    </div>
  )
}

export default Services