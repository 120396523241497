import React, { useEffect } from 'react'
import Image1 from './IMAGE1.png';
import Image2 from './IMAGE2.png';
import Image3 from './IMAGE3.png';
import Image4 from './IMAGE4.png';
import Image5 from './IMAGE5.png';
import Image6 from './IMAGE6.png';
import Image7 from './IMAGE7.png';
import Image8 from'./IMAGE8.png';
import './coreValues.css' 
import Aos from 'aos'
import 'aos/dist/aos.css'

const CoreValues = () => {
    useEffect(()=> {
        Aos.init({
            duration: 1000
        })
      },[])
    
    const values = [
        {
            id: 1,
            image: Image1,
            title: "Leadership",
        },
        {
            id: 2,
            image: Image2,
            title: "Collaborations"
        },
        {
            id: 3,
            image: Image3,
            title: "Swift Service Delivery"
        },
        {
            id: 4,
            image: Image4,
            title: "Customers Satisfaction"
        },
        {
            id: 5,
            image: Image5,
            title: "Team Work"
        },
        {
            id: 6,
            image: Image6,
            title: "Innovation"
        },
        {
            id: 7,
            image: Image7,
            title: "Professionalism"
        },
        {
            id: 8,
            image: Image8,
            title: "Employee's Satisfaction"
        },
    ]
  return (
    <div className='core-values'>
        <h1>Core Values</h1>
        <div className="core-values-block">
            {
                values.map((value)=> 
                    <div data-aos="fade-up" className="value" key={value.id}>
                        <img className='value-image' src={value.image}/>
                        <h4>{value.title}</h4>
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default CoreValues