import React, { useEffect } from 'react'
import './erpComponent.css'
import image1 from './product.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const ErpComponent = () => {
  useEffect(()=> {
    Aos.init({
        duration: 1000
    })
  },[])

  return (
    <div className='erp'>
        <img data-aos="flip-right" className='erp-image' src={image1}/>
        <div data-aos="flip-left" className="erp-content">
          <div className="erp-header">
            <p>//ERP</p>
            <h1>Enterprise Resource Planning</h1>
          </div>
          <div className="erp-desc">
            <p>Our ERP System is aimed at managing the day-to-day activities of manufacturing businesses, retail sales, distribution companies etc.
              it handles their sales, purchases, accounting, stock/inventory, human resources, production, etc.
            </p>
          </div>
        </div>
    </div>
  )
}

export default ErpComponent