import React, { useEffect } from 'react';
import './goals.css';
import goals from './goals.jpg'
import Background from './background.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Goals = () => {
    useEffect(()=> {
        Aos.init({
            duration: 1000
        })
    },[])

  return (
    <div style={{backgroundImage: `url(${Background})`}} className='goals'>
            <div className="goals-container">
                <div data-aos="fade-right" className="goals-content">
                    <div className="goals-header">
                        <p>//MISSON</p>
                        <h1>Our Goal</h1>
                    </div>
                    <div className="goals-desc">
                        <p>We have just one goal, which is, to provide affordable IT solutions to businesses all over the world.</p>
                    </div>
                </div>
                <div data-aos="fade-left">
                    <img className="goals-image" width='500px' src={goals}/>
                </div>
            </div>
    </div>
  )
}

export default Goals