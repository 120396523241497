import React, { useEffect } from 'react'
import './clients.css'
import BonHotel from './BON-hotel-Logo.png'
import IMSU from './imsu.png'
import IMCO from './imco.png'
import ENSUBEB from './ensubeb.png'
import ESG from './ESG.jpg'
import Neimeth from './neimeth.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Clients = () => {
  useEffect(()=> {
    Aos.init({
        duration: 1000
    })
  },[])

  return (
    <div className='clients-container'>
      <div data-aos="fade-up" className="clients-header">
        <p>//CLIENTS</p>
        <h1>Our Clients </h1>
      </div>
      <div data-aos="fade-up" data-aos-duration="1500" className='clients'>
      <img className='client-image' src={IMSU} width="80px"/>
      <img className='client-image' src={IMCO} width="160px" height='50px'/>
      <img className='client-image' src={Neimeth} width="120px"/>
      <img className='client-image' src={ESG} width="180px"/>
        <img className='client-image' src={BonHotel} width="120px"/>
         <img className='client-image' src={ENSUBEB} width="100px"/>
       
        
      </div>
    </div>
  )
}

export default Clients