import React from 'react'
import Banner from '../../components/Banner/Banner'
import Hero from '../../components/Hero/Hero'
import Navbar from '../../components/Navbar/Navbar'
import './product.css'
import image from './product.png'
import ErpComponent from '../../components/ProductComponent/erpComponent/ErpComponent'
import AccessControl from '../../components/ProductComponent/accessComponents/AccessControl'
import RenewableEnergy from '../../components/ProductComponent/renewableEnergyComponent/RenewableEnergy'
import Footer from '../../components/footer/Footer'
const Product = () => {
  return (
    <div>
      <Banner/>
      <Navbar active='productPage'/>
      <Hero
        title='Lots of Automated Products Built Specially For You.'
        desc='We have several products ranging from our ERP to Access Control and lots more.'
        button='Contact Us'
        link='contact-us'
        image={image}
      />
      <ErpComponent/>
      <AccessControl/>
      <RenewableEnergy/>
      <Footer/>
    </div>
  )
}

export default Product