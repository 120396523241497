import React, { useEffect } from 'react'
import './homeErp.css'
import image from './erp.png'
import {FcCheckmark} from 'react-icons/fc'
import {BsCheck2All} from 'react-icons/bs'
import Aos from 'aos'
import 'aos/dist/aos.css'

const HomeERP = () => {
  useEffect(()=> {
    Aos.init({
        duration: 1000
    })
  },[])

  return (
    <div className='home-erp'>
        <img data-aos="flip-right" src={image} className="home-erp-image" />
        <div data-aos="flip-left" className="home-erp-content">
          <div className="home-erp-header">
              <p>//ERP</p>
              <h1>Our Enterprise Resource Planning</h1>
          </div>
          <div className="home-erp-desc">
              <p> Our Enterprise Resource Planning System is aimed at managing the day-to-day activities of manufacturing businesses, retail sales, distribution companies etc. It handles their sales, purchases, accounting, stock/inventory, human resources, production, etc.</p>
              <div className="home-erp-lists">
                <p className='list'><BsCheck2All color='#98c03b' fontWeight='500' fontSize='18px'/> <i>Enhance business reporting </i></p>
                <p className='list'><BsCheck2All color='#98c03b' fontWeight='500' fontSize='18px'/> <i>Better customer service</i></p>
                <p className='list'><BsCheck2All color='#98c03b' fontWeight='500' fontSize='18px'/> <i>Improve inventory costs</i></p>
                <p className='list'><BsCheck2All color='#98c03b' fontWeight='500' fontSize='18px'/> <i>Boost cash flow</i></p>
                <p className='list'><BsCheck2All color='#98c03b' fontWeight='500' fontSize='18px'/> <i>Automate manual and routine task</i></p>
                <p className='list'><BsCheck2All color='#98c03b' fontWeight='500' fontSize='18px'/> <i>Supply chain management</i></p>
              </div>
          </div>
        </div>
    </div>
  )
}

export default HomeERP