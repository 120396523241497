import React, { useEffect } from 'react'
import './accessControl.css'
import image from './security.png'
import Background from './background.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const AccessControl = () => {
  useEffect(()=> {
    Aos.init({
        duration: 1000
    })
  },[])

  return (
    <div style={{backgroundImage: `url(${Background})`}} className='access-control'>
        <div data-aos="zoom-in-right" className="access-control-content">
            <div className="access-control-header">
                <p>//SECURITY</p>
                <h1>Access Control System</h1>
            </div>
            <div className="access-control-desc">
                <p>Newistt offers a wide range of hardware and access control systems, such as face; identification, fingerprint authentication, access cards, automatic doors, electric gates, elevators, intercom and IP phones.</p>
            </div>
        </div>
        <img data-aos="zoom-in-left" src={image} className="access-control-image"/>
    </div>
  )
}

export default AccessControl