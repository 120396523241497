import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home/Home';
import Product from './pages/Product/Product'
import Services from './pages/Services/Services';
import OurCompany from './pages/OurCompany/OurCompany';
import ContactUs from './pages/ContactUs/ContactUs';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/products" element={<Product/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/our-company" element={<OurCompany/>}/>
        <Route path="/contact-us" element={<ContactUs/>}/>
      </Routes>


    </BrowserRouter>
    
  );
}

export default App;
