import React from 'react'
import './footer.css'
import {RiFacebookBoxFill} from 'react-icons/ri'
import {BsLinkedin} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
const Footer = () => {
  return (
    <footer className='footer'>
        <div className="footer-container">
            <div className="copyright">
                <p>Copyright © 2022 Newistt. All rights reserved.</p>
            </div>
            <div className="footer-icons">
                <RiFacebookBoxFill cursor="pointer"/>
                <BsInstagram cursor="pointer"/>
                <BsLinkedin cursor="pointer"/>
            </div>
        </div>
    </footer>
  )
}

export default Footer