import React, { useEffect } from 'react'
import './servicesBlock.css'
import Networking from './server.png'
import Security from './secure.png'
import DigitalTool from './digital-tools.png'
import SmartHome from './smart-home.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const ServicesBlock = () => {
    useEffect(()=> {
        Aos.init({
            duration: 1000
        })
        },[])

    const firstData = [
        {
            id: 1,
            title: 'Network and Infrastructure',
            desc: 'We provide network solutions that ranges from LAN (Local Area Network), WAN (Wide Area Network), etc.',
            image: Networking,
            duration:"1300"
        },
        {
            id: 2,
            title: 'Security Systems',
            desc: 'Our security ranges from closed-circuit television (CCTV) cameras for environment monitoring and live streaming. Network security also comes with the use of bandwidth management and a robust firewall.',
            image: Security,
            duration:"1500"
        }
    ]
    const secondData = [
        {
            id: 3,
            title: 'Smart Homes',
            desc: 'Home automation systems give you centralized control of lighting, AC, windows, home entertainment, security systems and other appliances. Smart home automation delivers comfort and security.',
            image: SmartHome ,
            duration:"1700"
        },
        {
            id: 4,
            title: 'IT Consultation',
            desc: 'We advise, plan, design and install technology systems for businesses and brands. Reach out to us for all your IT consultations.',
            image: DigitalTool,
            duration:"1900"
        }
    ]

  return (
    <div className='services-block-container'>
        <div data-aos="fade-right" className="services-block-header">
            <p>//WHAT WE DO</p>
            <h1>Newistt offer all kinds of IT solutions</h1>
        </div>
        <div className="services-block">
            <div className="first-block-div">
                {
                    firstData.map((first)=>
                        <div data-aos-duration={first.duration} data-aos="fade-up" className="div-block" key={first.id}>
                            <img className='block-image' src={first.image} />
                            <h4>{first.title}</h4>
                            <p>{first.desc}</p>
                        </div>
                    )
                }
            </div>
            <div className="second-block-div">
                {
                    secondData.map((second)=>
                        <div data-aos-duration={second.duration} data-aos="fade-up" className="div-block" key={second.id}>
                            <img className='block-image' src={second.image} />
                            <h4>{second.title}</h4>
                            <p>{second.desc}</p>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default ServicesBlock