import React, { useEffect } from 'react'
import BackgroundImg from './banner.jpg'
import './servicesBanner.css'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

const ServicesBanner = () => {
  useEffect(()=> {
    Aos.init({
        duration: 1000
    })
  },[])

  return (
    <div data-aos="flip-up" className='services-banner' style={{backgroundPosition: 'center', backgroundSize: 'cover' ,backgroundImage: `url(${BackgroundImg})`}}>
        <h1>New Is Technology.</h1>
        <Link to='/contact-us'>
            <button className="services-banner-button">
                Contact Us
            </button>
        </Link>
    </div>
  )
}

export default ServicesBanner