import React, { useEffect } from 'react'
import './WhoWeAre.css'
import image1 from './wwa.jpg'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

const WhoWeAre = () => {
    useEffect(()=> {
        Aos.init({
            duration: 1000
        })
    },[])

  return (
    <div className="wwa">
        <div className='wwa-container' >
            <div data-aos="fade-left">
                <img className="wwa-image" width='500px' src={image1}/>
            </div>
            <div data-aos="fade-right" className="wwa-content">
                <div className="wwa-header">
                    {/* <p>//About Us</p> */}
                    <h1>Who We Are</h1>
                </div>
                <div className="wwa-desc">
                    <p>We are a group of professionals that offers various innovative solutions to suit your exact need.
                        Our products stand out from that of our competitors and gives your brand or business the right boost.
                       <br />
                        We are the NEW-TECHNOLOGY.
                    </p>
                </div>
                <Link to='/our-company'>
                    <button className="wwa-button">
                        Read More
                    </button>
                </Link>
            </div>
        </div>
    </div>
    
  )
}

export default WhoWeAre