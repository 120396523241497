import React from 'react'
import Banner from '../../components/Banner/Banner';
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/footer/Footer'
import Hero from '../../components/Hero/Hero';
import HomeServices from '../../components/HomeComponents/Home-Services/HomeServices';
import WhoWeAre from '../../components/HomeComponents/Who-We-Are/WhoWeAre'
import Goals from '../../components/HomeComponents/Goals/Goals'
import HomeERP from '../../components/HomeComponents/Home-ERP/HomeERP'
import Testimonials from '../../components/HomeComponents/Testimonials/Testimonials'
import image from './hero.png'
import Clients from '../../components/clients/Clients';
import Whatsapp from '../../components/Whatsapp/Whatsapp';

const Home = () => {
  return (
    <div>
        <Whatsapp/>
        <Banner/>
        <Navbar active='homePage'/>
        <Hero
          title='We Offer Modern IT Solutions With Ultra Quality.'
          desc='We play a key role in helping to meet the global growing demand for information and communication technology, process automation as well as service support across all sectors.'
          button='Read More'
          link='our-company'
          image={image}
          imgClass={true}
        />
        <WhoWeAre/>
        <Goals/>
        <HomeERP/>
        <HomeServices/>
        <Testimonials/>
        <Clients/>
        <Footer/>
    </div>
  )
}

export default Home