import React from 'react'
import Banner from '../../components/Banner/Banner'
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/Hero/Hero'
import Footer from '../../components/footer/Footer'
import TeamImage from './team.png'
import AboutUs from '../../components/OurCompanyComponents/AboutUs/AboutUs'
import CoreValues from '../../components/OurCompanyComponents/CoreValues/CoreValues'
const OurCompany = () => {
  return (
    <div>
        <Banner/>
        <Navbar active='companyPage'/>
        <Hero
        title="We Enable Progress by Solving the Technological Challenges faced by
        the Industry."
        desc="We have a decade history in creating platforms that breed connections for clients to procreate in their businesses daily."
        button="Read More"
        image={TeamImage}
        />
        <AboutUs/>
        <CoreValues/>
        <Footer/>
    </div>
  )
}

export default OurCompany