import React from 'react'
import Banner from '../../components/Banner/Banner'
import Form from '../../components/ContactUsComponents/Form/Form'
import Hero from '../../components/Hero/Hero'
import Navbar from '../../components/Navbar/Navbar'
import image from './contact.png'
import Footer from '../../components/footer/Footer'
const ContactUs = () => {
  return (
    <div>
        <Banner/>
        <Navbar active='contactPage'/>
        <Hero
        title="Our Trained Customer Service Providers Are Here To Help You."
        image= {image}
        display={false}
        />
        <Form/>
        <Footer/>
    </div>
  )
}

export default ContactUs