import React, { useEffect } from 'react'
import './homeservices.css'
import image1 from './IT.png'
import image2 from './server.png'
import image3 from './smart-home.png'
import Background from './background.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const HomeServices = () => {
    useEffect(()=> {
        Aos.init({
            duration: 1000
        })
    },[])

  return (
    <div style={{backgroundImage: `url(${Background})`}} className='home-services'>
        <div  data-aos="fade-up" className="home-services-header">
            <p>//SERVICES</p>
            <h1>We Offer a Wide Variety of IT Services</h1>
        </div>
        <div data-aos="fade-up" className="home-services-content">
            <div className="block">
                <img src={image1} className='block-image'/>
                <h4>IT Consultation</h4>
                <p>We offer advisory services that help clients access different technology strategies. These services support customer's IT initiatives.</p>
            </div>
            <div className="block">
                <img src={image2} className='block-image'/>
                <h4>Network & Infrastructure</h4>
                <p>Newistt offers state-of-the-art Network and infrastructure development for businesses. We provide network and internet solutions for your business, schools and cooperate organizations.</p> 
            </div>
            <div className="block">
                <img src={image3} className='block-image'/>
                <h4>Renewable Energy</h4>
                <p>At Newistt, we promote the penetration of renewable energy to provide reliable and sustainable energy that is able to meet the growing energy transition.
                This includes; system design, supply of equipments and installations.</p>
            </div>

        </div>
    </div>
  )
}

export default HomeServices