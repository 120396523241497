import React, { useEffect, useRef } from 'react'
import './form.css'
import {AiOutlineMail} from 'react-icons/ai'
import {FiPhone} from 'react-icons/fi'
import {FiMapPin} from 'react-icons/fi'
import Aos from 'aos'
import 'aos/dist/aos.css'
import emailjs from 'emailjs-com'

const Form = () => {

const contact = useRef()
const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm('service_9gmg37n', 'template_98txq1e', contact.current, '8Debeif9POegSDgNS')
    .then((result) => {
        alert('Email successfully sent');
        e.target.reset()
    }, (error) => {
        console.log(error.text);
    });
}

    useEffect(()=> {
        Aos.init({
            duration: 1000
        })
      },[])

  return (
    <div className='form'>
        <form ref={contact} data-aos="fade-right" onSubmit={sendEmail}  >
            <label>
                <p>Name</p>
            </label>
            <input type="text" name="name" placeholder='Enter your name'/>
            <label>
                <p>Email Address</p>
            </label>
            <input type="email" name="email" placeholder='Enter your email address' />
            <label> 
              <p>Type of Enquiry</p> 
               </label>
            <select name="option" id="">
                <option value="ERP">ERP</option>
                <option value="Access Control">Access Control</option>
                <option value="Renewable Energy">Renewable Energy</option>
                <option value="Network and Infrastructure">Network and Infrastructure</option>
                <option value="IT Consultation">IT Consultation</option>
                <option value="Others">Others</option>
            </select>
            <label>
                <p>Message</p>
            </label>
            <textarea placeholder='Your message here...' name='message'/>
            <button className="form-btn">Submit</button>
        </form>
        <div data-aos="fade-left" className="contact-info">
            <h1>Get in touch</h1>
            <p>Need to talk to us about your IT needs? Please use the form below to
            contact us and we would get back to you in less than 24 working
            hours. Alternatively, you can call us on the number listed below to
            speak directly to one of our customer service team members.</p>
            <div className="icons-container">
                <AiOutlineMail className="icon" />
                <p>info@newistt.com</p>
            </div>
            <div className="icons-container">
                <FiPhone className="icon" />
                <p>+(234) 816 266 1972</p>
            </div>
            <div className="icons-container">
                <FiMapPin className="icon" />
                <p>Newistt House, No 3B Onoh Cresent, GRA Enugu</p>
            </div>

        </div>
    </div>
  )
}

export default Form