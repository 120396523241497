import React, { useEffect } from 'react'
import './testimonials.css'
import Background from './uptect-inner-dots.png'
import {ImQuotesRight} from 'react-icons/im'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Testimonials = () => {
  useEffect(()=> {
    Aos.init({
        duration: 1000
    })
  },[])

  const data = [
    {
      id: 1,
      testimony: 'Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!',
      username: 'BON-Hotel',
      duration: "1000"
    },
    {
      id: 2,
      testimony: 'Excellent service delivery and good attention. We enjoyed working with the Newistt team as they were effective and committed.',
      username: 'Enugu State Government',
      duration: "1500"
    }
  ]

  return (
    <div 
    style={{background: ` url(${Background})`, 
    backgroundPosition: 'center', 
    backgroundSize: 'cover'}} 
    className='testimonials'>
        <div className="testimonials-container">
          <div className="testimonials-content">
            {data.map((item)=> 
              <div data-aos="flip-up" data-aos-duration={item.duration} className="testimonial-block" key={item.id}>
                <ImQuotesRight className='quote-icon' color='#214d6f'/>
                <p>{item.testimony}</p>
                <h4>{item.username}</h4>
              </div>
            )}
          </div>
          <div data-aos="fade-left" className="testimonials-header">
              <p className='tag'>//OUR CLIENTS</p>
              <h1>Our Customers Love What We Do</h1>
              <p>Hear success stories straight from our customers. We strive to meet the needs of our customers and provide you with quick and timely service.</p>
              <Link to='/our-company'>
                <button className="testimonials-button">
                    Contact Us
                </button>
              </Link>
          </div>
        </div>
    </div>
  )
}

export default Testimonials