import React from 'react'
import './whatsapp.css'
import {BsWhatsapp} from 'react-icons/bs'

const Whatsapp = () => {
  return (
    <div className='whatsapp'>
        <p>Need help? Chat with us!</p>
        <a href='#'>
            <div className="whatsapp-icon-container">
                <BsWhatsapp color='white' fontSize='28px'/>
            </div>
        </a>
    </div>
  )
}

export default Whatsapp