import React, { useEffect } from 'react';
import './aboutUs.css';
import Mission from './mission.png';
import Background from './background.png';
import Aos from 'aos'
import 'aos/dist/aos.css'

const AboutUs = () => {
  useEffect(()=> {
    Aos.init({
        duration: 1000
    })
  },[])

  const data = [
    {
      id: 1,
      title: "Our Vision",
      text: "To provide platforms that enable millions of people from different parts of the world and businesses to achieve more daily.",
      duration: "1500"
    },
    {
      id: 2,
      title: "Our Mission",
      text: "We create and deliver cutting-edge technology solutions that enable our customers and clients to meet their needs.",
      duration: "1800"
    },
    {
      id: 3,
      title: "Our Goals",
      text: "With our all-inclusive team of professionals, we aim to provide affordable IT and power solutions to businesses and homes all over the world.",
      duration: "1900"
    }
  ]
  return (
    <div className="about-us" style={{backgroundImage: `url(${Background})`}}>
      <div data-aos="flip-up" className="about-us-body">
        <h1>About Us</h1>
        <p>
          Newistt Business Services Limited is an innovative technology
          solution company, that plays a key role in helping the global
          growing demand for information and communication technology,
          process automation, renewable energy as well as service support across
          all sectors.
          <br />
          <br />
          Newistt has half a decade of history in creating platforms that
          breed connections for clients to procreate values in their
          businesses daily. Our products are driven to empower our clients
          to get maximum value, deliver value to their clients and stay
          ahead as well as being pacesetters to their competitors.
          <br />
          <br />
          Our clients reflect our productive nature and as such, we keep
          taking a consistent approach to creating and delivering
          cutting-edge technology solutions for our customers, while building
          our client/employee relationship, with customer focus and
          excellence at the forefront.
          <br />
          <br />
          We knew that by By aligning our incentives with our customers, we
          could build something truly significant, with that in mind, we set
          out to deliver sophisticated financial software, security
          technology, internet services, renewable energy solutions, and consultancies.
        </p>
      </div>
      <div className="about-us-blocks">
        <img data-aos="flip-up" data-aos-duration="1300" src={Mission} className='about-us-image' />
        {
          data.map((item) => 
            <div data-aos="flip-up" data-aos-duration={item.duration} className="about-us-block" key={item.id} >
              <h4>{item.title}</h4>
              <p>{item.text}</p>
            </div>          
          )
        }
      </div>
    </div>
  );
};

export default AboutUs;
