import React, { useEffect } from 'react'
import './hero.css'
import { Link } from 'react-router-dom';
import Aos from 'aos'
import 'aos/dist/aos.css'

const Hero = ({title, desc, button, link, display, image, imgClass}) => {
  useEffect(()=> {
    Aos.init({
        duration: 1000
    })
  },[])

  return (
    <div className='hero'> 
      <div data-aos="fade-right" className="hero-content">
        <h1 className='hero-title'>{title}</h1>
        <p className='hero-desc'>{desc}</p>
        <Link to={`/${link}`}>
          <button className={display == false ? 'no-display' : 'hero-button'} >
            {button}
          </button>
        </Link>
      </div>
      <div data-aos="fade-left" className="hero-image">
        <img className={imgClass ? 'h-image-edited' : 'h-image'} src={image}/>
      </div>
    </div>
  )
}

export default Hero