import React, { useState } from 'react';
import Logo from './newisttLogo.png';
import {VscClose} from 'react-icons/vsc'
import {RiMenu3Fill} from 'react-icons/ri'
import './navbar.css'
import { Link } from 'react-router-dom';

const Navbar = ({active}) => {
  //set navbar box-shadow
  const [shadow, setShadow] = useState(false)
  const addShadow = () => {
    if(window.scrollY >= 90){
      setShadow(true)
    }else{
      setShadow(false)
    }
  }
  window.addEventListener('scroll', addShadow)

  //set mobile-menu
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)

  return (
    <div className={shadow ? 'navbar navbar-bx' : "navbar"}>
      <div className="navbar-container">
        <div className="logo">
          <Link to='/'>
            <img className='logo-image' src={Logo}/>
          </Link>

        </div>
        <div className="nav-links">
              <Link to='/' className={active === 'homePage' ? 'nav-link-item active' : 'nav-link-item'}>Home</Link>
              <Link to='/products' className={active === 'productPage' ? 'nav-link-item active' : 'nav-link-item'}>Products</Link>
              <Link to='/services' className={active === 'servicesPage' ? 'nav-link-item active' : 'nav-link-item'}>Services</Link>
              <Link to='/our-company' className={active === 'companyPage' ? 'nav-link-item active' : 'nav-link-item'}>Our Company</Link>
              <Link to='/contact-us' className={active === 'contactPage' ? 'nav-link-item active' : 'nav-link-item'}>Contact Us</Link>
              <div className="menu-icon" onClick={handleClick}>
                {click ? <VscClose/> : <RiMenu3Fill/>}
              </div>

          </div>
      </div>
      <div className={click ? "mobile-menu open" : "mobile-menu close"}>
        <div className="mobile-menu-links">
          <Link to='/' className='mobile-nav-link-item'>Home</Link>
          <Link to='/products' className='mobile-nav-link-item'>Products</Link>
          <Link to='/services' className='mobile-nav-link-item'>Services</Link>
          <Link to='/our-company' className='mobile-nav-link-item'>Our Company</Link>
          <Link to='/contact-us' className='mobile-nav-link-item'>Contact Us</Link>
        </div>
      </div>
    </div>
  )
}

export default Navbar